import { ProjectQuery_project } from "./../__generated__/ProjectQuery";
import { ProjectStatus } from "../__generated__/globalTypes";

export const isCancelable = (project: ProjectQuery_project | null) => {
  return (
    project != null &&
    (project.status === ProjectStatus.NOT_STARTED ||
      project.status === ProjectStatus.WAITING_FOR_QUOTE)
  );
};
