import axios, { AxiosError } from "axios";
import StatusCode from "http-status-codes";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { useAnalytics } from "../../lib/analytics";
import { useAuth } from "../Auth/context";
import { Dialog, DialogProps } from "../Dialog";
import { Redirect } from "../Redirect";
import { CreateProjectForm, NewProjectData, NewProjectErrors } from "./create";
import { SelectServiceForm } from "./service";

export interface NewProjectDialogProps extends DialogProps {}

export const NewProjectDialog = ({
  open,
  onClose,
  ...props
}: NewProjectDialogProps) => {
  const history = useHistory();

  const auth = useAuth();
  const analytics = useAnalytics();

  const selectServiceRoute = useRouteMatch("/new-project/select-service");
  const enterDetailsRoute = useRouteMatch("/new-project/enter-details");

  const [project, setProject] = useState<NewProjectData>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<NewProjectErrors>({});

  const handleClose = useCallback(
    (event, reason) => {
      setProject({});
      setErrors({});
      onClose?.(event, reason);
    },
    [setProject, onClose]
  );

  const handleSubmit = useCallback(async () => {
    analytics.event("New Project", "Submit");
    setErrors({});

    if (!project.service) {
      history.push("/new-project/select-service");
      return;
    }

    if (!project.projectType) {
      analytics.event("New Project", "Error");
      setErrors({
        projectType: _.isEmpty(project.projectType),
        message: "Please select a project type",
      });
      return;
    }
    if (
      !project.name?.length ||
      !project.description?.length ||
      !project.sourceLanguageCode?.length ||
      !project.targetLanguageCodes?.length
    ) {
      analytics.event("New Project", "Error");
      setErrors({
        name: _.isEmpty(project.name),
        description: _.isEmpty(project.description),
        sourceLanguageCode: _.isEmpty(project.sourceLanguageCode),
        targetLanguageCodes: _.isEmpty(project.targetLanguageCodes),
        deliveryAt: project.deliveryAt != null && isNaN(project.deliveryAt),
        message: "Some required details are missing",
      });
      return;
    }

    if (project.projectType === "website" && !project.websiteLink?.length) {
      analytics.event("New Project", "Error");
      setErrors({
        websiteLink: _.isEmpty(project.websiteLink),
        message: "You must provide a website link for a website-based project",
      });
      return;
    } else if (project.projectType === "files" && !project.files?.length) {
      analytics.event("New Project", "Error");
      setErrors({
        files: _.isEmpty(project.files),
        message: "You must upload at least one file for a file-based project",
      });
      return;
    } else if (
      project.projectType === "subtitle/vo/dub" &&
      !project.files?.length &&
      !project.websiteLink?.length
    ) {
      analytics.event("New Project", "Error");
      setErrors({
        files: _.isEmpty(project.files),
        websiteLink: _.isEmpty(project.websiteLink),
        message:
          "You must upload at least one file or provide a website link for a Subtitle / VO / Dub project",
      });
      return;
    }

    if (project.deliveryAt != null && isNaN(project.deliveryAt)) {
      analytics.event("New Project", "Error");
      setErrors({
        deliveryAt: true,
        message: "The deadline is missing",
      });
      return;
    }
    const anyErrors =
      Object.values(errors).filter((errored) => errored === true).length > 0;
    if (anyErrors) {
      analytics.event("New Project", "Error");
      return;
    }

    const processedDescription = `${project.description}\n\nProject type: ${
      project.projectType
    }${project.websiteLink ? `\nWebsite link: ${project.websiteLink}` : ""}`;

    setLoading(true);
    const formData = new FormData();
    formData.append("service", project.service);
    formData.append("name", project.name);
    formData.append("description", processedDescription);
    formData.append("sourceLanguageCode", project.sourceLanguageCode);
    formData.append(
      "targetLanguageCodes",
      project.targetLanguageCodes.join(",")
    );
    (project.files || []).map((file) => formData.append("files", file));
    (project.referenceFiles || []).map((file) =>
      formData.append("referenceFiles", file)
    );
    formData.append(
      "quoteRequested",
      project.quoteRequested ? "true" : "false"
    );
    if (project.deliveryAt) {
      formData.append("deliveryAt", project.deliveryAt.toString());
    }

    try {
      const authHeaders = await auth.getAuthHeaders();
      const result = await axios.post<{ id: string }>(
        "/api/projects/create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...authHeaders,
          },
        }
      );
      history.push(`/project/${result.data.id}`);
      setProject({});
    } catch (error) {
      analytics.event("New Project", "Failure");
      analytics.error(error);
      setErrors(
        parseErrors(error) || {
          message:
            "Darn it! Something went wrong. Please try again or contact us.",
        }
      );
    } finally {
      setLoading(false);
    }
  }, [errors, setErrors, analytics, project, auth, history, setProject]);

  return (
    <React.Fragment>
      <Dialog
        open={open && selectServiceRoute != null}
        onClose={handleClose}
        disableExitButton
        maxWidth="lg"
        {...props}
      >
        <SelectServiceForm
          value={project.service}
          onChange={(service) => {
            if (service) {
              setProject({ ...project, service });
              setTimeout(() => history.push("/new-project/enter-details"), 500);
            }
          }}
        />
      </Dialog>
      <Dialog
        scroll="body"
        open={open && enterDetailsRoute != null}
        onClose={(event, reason) => {
          analytics.event("New Project", "Close");
          handleClose(event, reason);
        }}
        confirmBeforeClose={
          Object.keys(project).filter((key) => key !== "service").length > 0
        }
        {...props}
      >
        <CreateProjectForm
          project={project}
          onChange={(key, project) => {
            setProject(project);
            if (key === "files" || key === "websiteLink") {
              setErrors(_.omit(errors, "files", "websiteLink", "message"));
            } else {
              setErrors(_.omit(errors, key, "message"));
            }
          }}
          onSubmit={() => handleSubmit()}
          loading={loading}
          errors={errors}
          onError={setErrors}
        />
      </Dialog>
      {open && !selectServiceRoute && !enterDetailsRoute && (
        <Redirect delayed={false} to="/new-project/select-service" />
      )}
      {open && enterDetailsRoute && !project.service && (
        <Redirect delayed={false} to="/new-project/select-service" />
      )}
    </React.Fragment>
  );
};

const parseErrors = (error: AxiosError | any): NewProjectErrors | null => {
  if (!error.isAxiosError) {
    return null;
  }

  const axiosError = error as AxiosError;
  if (axiosError.response?.status === StatusCode.BAD_REQUEST) {
    const errors = (
      axiosError.response.data?.errors || []
    ).filter((error: any) =>
      _.includes(
        [
          "name",
          "description",
          "sourceLanguageCode",
          "targetLanguageCodes",
          "files",
          "referenceFiles",
          "deliveryAt",
        ],
        error.param
      )
    );
    return {
      ..._.chain(errors)
        .keyBy("param")
        .mapValues(() => true)
        .value(),
      message:
        "Some of the details aren't valid. Please fix them or contact us.",
    };
  } else if (axiosError.response?.status === StatusCode.REQUEST_TOO_LONG) {
    return {
      message:
        "The files are too large. Please use smaller files or contact us.",
    };
  } else {
    return null;
  }
};
