import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useNextParam } from "../../lib/next";
import { useAuth } from "../Auth/context";
import { Redirect } from "../Redirect";
import { UserBadge } from "../UserBadge";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const EmbeddedForm = ({ src }: { src: string }) => {
  const auth = useAuth();
  const [isSignedIn, setSignedIn] = useState(true);
  useEffect(() => {
    const subscription = auth
      .isSignedIn()
      .subscribe((isSignedIn) => setSignedIn(isSignedIn));
    return () => subscription.unsubscribe();
  }, [auth, setSignedIn]);

  const classes = useStyles();
  const [, addNextParam] = useNextParam({
    toCurrentPage: true,
  });
  // Iframe taking up full screen with the UserBadge component overlayed on top in the top right
  return (
    <div className={classes.root}>
      <UserBadge />
      <iframe
        className="airtable-embed"
        title="Embedded form"
        src={src}
        frameBorder="0"
        width="100%"
        height="100%"
        style={{ background: "transparent", border: "1px solid #ccc" }}
      ></iframe>
      {isSignedIn || <Redirect to={addNextParam("/sign-in")} />}
    </div>
  );
};

export const LocalizationFormPage = () => {
  return (
    <EmbeddedForm src="https://airtable.com/embed/appeSQNCTWaEl3YSW/pag83VfNWVzCBm8QR/form" />
  );
};

export const ConsultingFormPage = () => {
  return (
    <EmbeddedForm src="https://airtable.com/embed/appxtEr36ifeka6pC/pagVFuJBPptbWI0R1/form" />
  );
};
