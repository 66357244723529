import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";

import { useAnalytics } from "../../lib/analytics";
import { CssBaseline } from "../CssBaseline";
import { ConsultingFormPage, LocalizationFormPage } from "../EmbeddedForm";
import { HomePage } from "../HomePage";
import { NewPasswordPage } from "../NewPasswordPage";
import { SignInPage } from "../SignInPage";
import { AppProviders } from "./providers";

export const App = () => {
  return (
    <AppProviders>
      <CssBaseline />
      <Router>
        <PageViewTracker />
        <Switch>
          <Route path="/localize" component={LocalizationFormPage} />
          <Route path="/consulting" component={ConsultingFormPage} />
          <Route path="/sign-in" component={SignInPage} />
          <Route path="/new-password" component={NewPasswordPage} />
          <Route path="/" component={HomePage} />
        </Switch>
      </Router>
    </AppProviders>
  );
};

const PageViewTracker = () => {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.pageview();
  }, [location.pathname, analytics]);

  return null;
};
