import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useState } from "react";

import { ExternalLinkIcon } from "../../icons/ExternalLinkIcon";

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 600,
    transition: theme.transitions.create(["color"], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  icon: {
    marginLeft: "3px",
  },
}));

export interface LinkProps extends MuiLinkProps {
  colored?: "always" | "hover" | "none";
  external?: boolean;
}

export const Link = ({
  className,
  href,
  underline = "hover",
  colored = "always",
  color,
  external = false,
  onClick,
  children,
  target,
  ...props
}: LinkProps) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  const handleClick = useCallback(
    (event) => {
      onClick?.(event);
      if (!href) {
        event.preventDefault();
      }
    },
    [onClick, href]
  );

  return (
    <MuiLink
      className={clsx(className, classes.link)}
      underline={underline}
      href={href || "#"}
      onClick={handleClick}
      onPointerEnter={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
      color={
        colored === "always" || (colored === "hover" && hover)
          ? color
          : "textSecondary"
      }
      target={target || (external ? "_blank" : undefined)}
      {...props}
    >
      <React.Fragment>
        {children}
        {external && <ExternalLinkIcon className={classes.icon} />}
      </React.Fragment>
    </MuiLink>
  );
};
