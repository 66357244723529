import {
  ButtonBase,
  ButtonBaseProps,
  DialogContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import { ServiceType } from "../../__generated__/globalTypes";
import { CalendarDayIcon } from "../../icons/CalendarDayIcon";
import { GlobeAmericasIcon } from "../../icons/GlobeAmericasIcon";
import { IconProps } from "../../icons/Icon";
import { LanguageIcon } from "../../icons/LanguageIcon";
import { UserHeadsetIcon } from "../../icons/UserHeadsetIcon";
import { Link } from "../Link";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "100px",
    paddingBottom: "40px",
  },
  title: {
    margin: "20px 0px 40px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "30px",
    margin: "10px 0px 15px",
  },
  type: {
    minWidth: "135px",
    height: "135px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "25px",
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "10px",
    transition: theme.transitions.create(["background-color", "color"], {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  typeDisabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderColor: theme.palette.action.disabled,
    color: theme.palette.action.disabled,
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  other: {
    gridColumn: "span 2",
    width: "100%",
    height: "40px",
    borderRadius: "10px",
    paddingTop: "inherit",
  },
  icon: {
    fontSize: "60px",
    marginBottom: "10px",
  },
  iconSelected: {
    transition: theme.transitions.create(["color"], {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.primary.contrastText,
  },
  label: {
    fontWeight: 600,
    fontSize: "14px",
  },
}));

export interface SelectServiceFormProps {
  value: ServiceType | undefined;
  onChange: (service: ServiceType) => void;
}

export const SelectServiceForm = ({
  value,
  onChange,
}: SelectServiceFormProps) => {
  const classes = useStyles();
  return (
    <DialogContent className={classes.root}>
      <Typography className={classes.title} variant="h2" gutterBottom>
        How can we help?
      </Typography>
      <div className={classes.grid}>
        <TypeButton
          label="Translate"
          selected={value === ServiceType.TRANSLATION}
          onClick={() => onChange(ServiceType.TRANSLATION)}
          Icon={LanguageIcon}
        />
        <TypeButton
          label={
            <>
              On-demand
              <br />
              interpreter
            </>
          }
          href="//comealive.interpretmanager.com/ng/#/app/account/sign-in"
          Icon={UserHeadsetIcon}
        />
        <TypeButton
          label="Localize"
          href="/localize"
          Icon={GlobeAmericasIcon}
        />

        <TypeButton
          label={
            <>
              Schedule
              <br />
              interpreter
            </>
          }
          href="//comealiveusa.com/request-an-interpreter"
          Icon={CalendarDayIcon}
        />
        <div className={classes.other}>
          <OtherTypeButton label="Consulting" href="/consulting" />
        </div>
      </div>
    </DialogContent>
  );
};

const TypeButton = React.forwardRef<
  HTMLButtonElement,
  {
    label: string | React.ReactNode;
    href?: string;
    selected?: boolean;
    Icon?: (props: IconProps) => JSX.Element;
  } & ButtonBaseProps
>(({ className, label, href, selected = false, Icon, ...props }, ref) => {
  const classes = useStyles();

  const content = (
    <ButtonBase
      ref={ref}
      className={clsx(className, classes.type, {
        [classes.selected]: selected,
      })}
      classes={{ disabled: classes.typeDisabled }}
      {...props}
    >
      {Icon && (
        <Icon
          className={clsx(classes.icon, { [classes.iconSelected]: selected })}
        />
      )}
      <Typography className={classes.label} variant="subtitle1" color="inherit">
        {label}
      </Typography>
    </ButtonBase>
  );
  return href ? (
    <Link href={href} target="_blank" underline="none">
      {content}
    </Link>
  ) : (
    content
  );
});

const OtherTypeButton = ({
  className,
  ...props
}: { label: string; selected?: boolean; href?: string } & ButtonBaseProps) => {
  const classes = useStyles();
  return <TypeButton className={clsx(className, classes.other)} {...props} />;
};
