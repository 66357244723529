/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum EventSource {
  ADMIN = "ADMIN",
  CLIENT = "CLIENT",
  MANAGER = "MANAGER",
}

export enum EventType {
  PROJECT_CANCELED = "PROJECT_CANCELED",
  PROJECT_COMPLETED = "PROJECT_COMPLETED",
  PROJECT_CREATED = "PROJECT_CREATED",
  QUOTE_REQUESTED = "QUOTE_REQUESTED",
  TRANSLATIONS_COMPLETED = "TRANSLATIONS_COMPLETED",
}

export enum ProjectFileStatus {
  ASSIGNED = "ASSIGNED",
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum ProjectStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
  WAITING_FOR_QUOTE = "WAITING_FOR_QUOTE",
}

export enum ServiceType {
  LOCALIZATION = "LOCALIZATION",
  MEDIA = "MEDIA",
  OTHER = "OTHER",
  TRANSLATION = "TRANSLATION",
}

export enum UserType {
  ADMIN = "ADMIN",
  CLIENT = "CLIENT",
}

export interface InviteUserInput {
  email: string;
  firstName: string;
  lastName: string;
  temporaryPassword: string;
  userType: UserType;
  smartcatId: string;
}

export interface UpdateUserNotificationSettingsInput {
  PROJECT_CREATED: boolean;
  PROJECT_COMPLETED: boolean;
  PROJECT_CANCELED: boolean;
}

export interface UpdateUserSettingsInput {
  billingAddress?: string | null;
  notifications?: UpdateUserNotificationSettingsInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
