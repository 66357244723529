import { useQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
  DialogContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

import {
  ProjectQuery,
  ProjectQueryVariables,
  ProjectQuery_project,
} from "../../__generated__/ProjectQuery";
import { useAnalytics } from "../../lib/analytics";
import { PROJECT_QUERY } from "../../lib/graphql";
import { isCancelable } from "../../lib/projectHelpers";
import { useAuth } from "../Auth/context";
import { Button } from "../Button";
import { Dialog } from "../Dialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
    paddingBottom: "20px",
    width: "55vw",
  },
  body: {
    marginBottom: "20px",
  },
  centered: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  title: {
    margin: "20px 0px 30px",
  },
  button: { marginLeft: "15px" },
  buttonGroup: {
    display: "flex",
    marginTop: "20px",
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
}));

export interface CancelDialogProps {
  projectId: string;
  open: boolean;
  onClose: (cancelSuccess: boolean | null) => void;
}

export const CancelDialog = ({
  projectId,
  open,
  onClose,
  ...props
}: CancelDialogProps) => {
  const classes = useStyles();
  const auth = useAuth();
  const analytics = useAnalytics();

  const [project, setProject] = useState<ProjectQuery_project | null>(null);
  const [canceling, setCanceling] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState<boolean | null>(null);

  const handleClose = useCallback(() => {
    if (!canceling) {
      onClose(cancelSuccess);
      setCancelSuccess(null);
      setCanceling(false);
    }
  }, [onClose, canceling, setCanceling, setCancelSuccess, cancelSuccess]);

  // Fetch up-to-date project info and update state when query returns
  const projectQueryResult = useQuery<ProjectQuery, ProjectQueryVariables>(
    PROJECT_QUERY,
    {
      variables: {
        id: projectId,
      },
    }
  );

  useEffect(() => {
    setProject(projectQueryResult.data?.project || null);
  }, [projectQueryResult.data, setProject]);

  // Handle cancel request
  const handleCancel = useCallback(async () => {
    if (project == null) {
      throw new Error("Project is null while trying to cancel!");
    }

    // Validate (again) that project is cancellable
    if (!isCancelable(project)) {
      alert(
        "This project can't be canceled! Progress has already been made, and fees may apply. Please contact us directly."
      );
      return;
    }

    analytics.event("Cancel Project", "Submit");
    try {
      setCanceling(true);
      const authHeaders = await auth.getAuthHeaders();
      await axios.post<{
        id: string;
      }>(
        "/api/projects/cancel",
        {
          projectId: project.id,
        },
        {
          headers: authHeaders,
        }
      );
      setCancelSuccess(true);
    } catch (error) {
      analytics.event("Cancel Project", "Failure");
      analytics.error(error);
      console.log(error);
      setCancelSuccess(false);
    } finally {
      setCanceling(false);
    }
  }, [project, analytics, auth, setCanceling, setCancelSuccess]);

  return (
    <Dialog
      open={open}
      disableExitButton
      maxWidth="lg"
      onClose={() => handleClose()}
      {...props}
    >
      <DialogContent className={classes.root}>
        {project == null ? (
          <CircularProgress />
        ) : cancelSuccess != null ? (
          cancelSuccess ? (
            <CancelSuccess />
          ) : (
            <CancelError />
          )
        ) : isCancelable(project) ? (
          <CancelableDialog
            onClose={() => {
              handleClose();
            }}
            onCancel={() => {
              handleCancel();
            }}
            canceling={canceling}
          />
        ) : (
          <NotCancelableDialog />
        )}
      </DialogContent>
    </Dialog>
  );
};

const CancelSuccess = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title} variant="h3">
        Project canceled successfully!
      </Typography>
      <Typography className={classes.body} variant="body1">
        As always, let us know if you have any questions or feedback — we are so
        grateful to work with you.
      </Typography>
    </div>
  );
};

const CancelError = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title} variant="h3">
        Error while cancelling.
      </Typography>
      <Typography className={classes.body} variant="body1">
        We encountered an error while trying to cancel this project.
        <strong>
          To cancel, please contact us directly by calling us at 610.869.3660 or
          sending an email to submit@comealiveusa.com.
        </strong>
      </Typography>
      <Typography className={classes.body} variant="body1">
        <strong>Please note:</strong> Cancellation requests are not automatic,
        but will be reviewed by a project manager within 24 hours of receipt. If
        work has begun, charges may apply.
      </Typography>
    </div>
  );
};

const NotCancelableDialog = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title} variant="h3">
        Unable to cancel
      </Typography>
      <Typography className={classes.body} variant="body1">
        Work on this project has already begun.{" "}
        <strong>
          To cancel, please contact us directly by calling us at 610.869.3660 or
          sending an email to submit@comealiveusa.com.
        </strong>
      </Typography>
      <Typography className={classes.body} variant="body1">
        <strong>Please note:</strong> Cancellation requests are not automatic,
        but will be reviewed by a project manager within 24 hours of receipt. If
        work has begun, charges may apply.
      </Typography>
    </div>
  );
};

const CancelableDialog = ({
  onCancel,
  onClose,
  canceling,
}: {
  onCancel: () => void;
  onClose: () => void;
  canceling: boolean;
}) => {
  const classes = useStyles();
  return (
    <div>
      {canceling ? (
        <div className={classes.centered}>
          <Typography className={classes.title} variant="h3" gutterBottom>
            Canceling...
          </Typography>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Typography className={classes.title} variant="h3" gutterBottom>
            Are you sure?
          </Typography>
          <Typography variant="body1" gutterBottom>
            This project can be canceled automatically. Are you sure you want to
            cancel? <strong>This can't be undone!</strong>
          </Typography>
          <div className={classes.buttonGroup}>
            <Button
              className={classes.button}
              color="primary"
              primaryAction
              onClick={() => {
                onClose();
              }}
            >
              No
            </Button>
            <Button
              className={classes.button}
              color="primary"
              primaryAction
              onClick={() => {
                onCancel();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
