import { Fade, Typography, makeStyles } from "@material-ui/core";
import _ from "lodash";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { useAnalytics } from "../../lib/analytics";
import { useNextParam } from "../../lib/next";
import { AuthStatus } from "../Auth";
import { useAuth } from "../Auth/context";
import { Button } from "../Button";
import { Link } from "../Link";
import { Redirect } from "../Redirect";
import { TextField } from "../TextField";
import Logo from "./logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    height: "174.98px",
    width: "300px",
    marginBottom: "32px",
  },
  form: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  input: {
    width: "350px",
    margin: theme.spacing(2),
  },
  error: {
    fontWeight: 700,
    fontSize: "14px",
  },
  button: {
    width: "200px",
    fontWeight: 900,
    fontSize: "24px",
    marginTop: "36px",
    marginBottom: "80px",
  },
  inviteOnlyText: {
    fontSize: "14px",
    fontFamily: "Playfair Display,serif",
  },
  termsAndConditionsText: {
    fontSize: "10px",
    fontFamily: "Playfair Display,serif",
  },
}));

export const SignInPage = () => {
  const classes = useStyles();
  const auth = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [next, addNextParam] = useNextParam();

  const [authStatus, setAuthStatus] = useState(AuthStatus.NONE);
  const [isAutoSigningIn, setAutoSigningIn] = useState(true);

  useEffect(() => {
    const { email, password } = queryString.parse(location.search);
    if (email && _.isString(email) && password && _.isString(password)) {
      auth.signIn(email, password).then(() => setAutoSigningIn(false));
    } else {
      setAutoSigningIn(false);
    }
  }, [location, auth, setAutoSigningIn]);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (
      !isAutoSigningIn &&
      (_.has(query, "email") || _.has(query, "password"))
    ) {
      history.push({
        search: queryString.stringify(_.omit(query, ["email", "password"])),
      });
    }
  }, [location, history, isAutoSigningIn]);

  useEffect(() => {
    const subscription = auth
      .getStatus()
      .subscribe((status) => setAuthStatus(status));
    return () => subscription.unsubscribe();
  }, [auth, setAuthStatus]);

  return (
    <Fade in={authStatus === AuthStatus.NONE && !isAutoSigningIn}>
      <div className={classes.root}>
        <Link href="http://comealiveusa.com" target="_blank">
          <img className={classes.logo} src={Logo} alt="Come Alive Logo" />
        </Link>
        <SignInForm />

        {authStatus === AuthStatus.CHALLENGED && (
          <Redirect to={addNextParam("/new-password")} />
        )}
        {authStatus === AuthStatus.SIGNED_IN && <Redirect to={next} />}
      </div>
    </Fade>
  );
};

const SignInForm = () => {
  const classes = useStyles();
  const auth = useAuth();
  const analytics = useAnalytics();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<"INVALID_CREDENTIALS" | null>(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = useCallback(
    (event) => {
      analytics.event("Sign In Page", "Submit");
      setLoading(true);
      event.preventDefault();
      auth.signIn(email, password).then((result) => {
        if (result.error) {
          analytics.event("Sign In Page", "Error");
          setError(result.error);
          setLoading(false);
        }
      });
    },
    [analytics, auth, email, password, setError]
  );

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        id="email"
        autoComplete="username"
        className={classes.input}
        value={email}
        variant="standard"
        type="text"
        onChange={(e) => {
          setEmail(e.target.value);
          setError(null);
        }}
        placeholder="Email Address"
        required
        error={error != null}
        margin="normal"
      />
      <TextField
        id="password"
        autoComplete="current-password"
        className={classes.input}
        value={password}
        variant="standard"
        type="password"
        onChange={(e) => {
          setPassword(e.target.value);
          setError(null);
        }}
        placeholder="Password"
        required
        error={error != null}
        margin="normal"
      />
      <Fade in={error === "INVALID_CREDENTIALS"}>
        <Typography
          id="error-text"
          className={classes.error}
          color="error"
          variant="body1"
        >
          Invalid email or password.
        </Typography>
      </Fade>
      <Button
        id="sign-in-button"
        className={classes.button}
        color="primary"
        elevated={true}
        type="submit"
        primaryAction
        disabled={loading}
      >
        Sign In
      </Button>
      <Typography className={classes.inviteOnlyText} variant="body2">
        This site is invite only.{" "}
        <Link href={buildRequestAccountLink()} underline="hover">
          Request an account.
        </Link>
      </Typography>
      <br />
      <Typography className={classes.termsAndConditionsText} variant="body2">
        View{" "}
        <Link
          href={"/terms_and_conditions.pdf"}
          target="_blank"
          underline="hover"
        >
          terms and conditions.
        </Link>
      </Typography>
    </form>
  );
};

const buildRequestAccountLink = () => {
  return (
    "mailto:info@ComeAliveUSA.com" +
    "?subject=Access to Come Alive Client Boutique" +
    "&body=Hi! I'd like to use Come Alive’s Client Boutique to manage my projects. Can I be invited?"
  );
};
