import { useQuery } from "@apollo/react-hooks";
import { Fade, Grid, Typography, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { ProjectStatus } from "../../__generated__/globalTypes";
import {
  ProjectsQuery,
  ProjectsQuery_projects,
  ProjectsQuery_projects_sourceLanguage,
} from "../../__generated__/ProjectsQuery";
import { SiteStatusQuery } from "../../__generated__/SiteStatusQuery";
import { ExclamationTriangleIcon } from "../../icons/ExclamationTriangleIcon";
import { LongArrowRightIcon } from "../../icons/LongArrowRightIcon";
import { PlusIcon } from "../../icons/PlusIcon";
import { useAnalytics } from "../../lib/analytics";
import { PROJECTS_QUERY, SITE_STATUS_QUERY } from "../../lib/graphql";
import { useNextParam } from "../../lib/next";
import { AdminDialog } from "../AdminDialog";
import { useAuth } from "../Auth/context";
import { Button } from "../Button";
import { NewProjectDialog } from "../NewProjectDialog";
import { Paper } from "../Paper";
import { ProjectDialog } from "../ProjectDialog";
import { Redirect } from "../Redirect";
import { SettingsDialog } from "../SettingsDialog";
import { Tooltip } from "../Tooltip";
import { UserBadge } from "../UserBadge";
import WelcomeGraphic from "./welcome.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 8),
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  newProjectButton: {
    marginTop: "14px",
    paddingTop: "2px",
    paddingBottom: "2px",
    width: "160px",
  },
  siteAlert: {
    marginBottom: "20px",
  },
  projectsList: {
    marginTop: "42px",
  },
  project: {
    padding: "17px",
    height: "50px",
    margin: "23px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid",
    borderColor: "rgba(0, 0, 0, 0.0)",
    transition: theme.transitions.create(["transform", "border-color"], {
      duration: theme.transitions.duration.shortest,
    }),
    "& > p": {
      transition: theme.transitions.create(["color"], {
        duration: theme.transitions.duration.shortest,
      }),
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
      "& > p": {
        color: theme.palette.primary.main,
      },
      transform: "scale3d(1.015, 1.015, 1)",
    },
    cursor: "pointer",
  },
  projectSummary: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#BDBDBD",
  },
  projectSummaryArrow: {
    fontSize: "18px",
  },
  languageSummaryWrapper: {
    minWidth: "115px",
    display: "flex",
  },
  languageSummary: {
    minWidth: "80px",
    border: "1px solid",
    borderRadius: "100px",
    padding: "4px 8px",
    margin: "0px 4px",
    transition: "none",
  },
  graphic: {
    marginTop: "64px",
    padding: "16px",
    display: "flex",
    alignItems: "flex-start",
  },
  image: {
    width: "100%",
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();
  const { data, loading, error, refetch } = useQuery<ProjectsQuery>(
    PROJECTS_QUERY,
    {
      pollInterval: 60 * 1000,
    }
  );
  const [, addNextParam] = useNextParam({
    toCurrentPage: true,
  });

  const [isSignedIn, setSignedIn] = useState(true);
  const [projects, setProjects] = useState<ProjectsQuery_projects[]>([]);

  useEffect(() => {
    const subscription = auth
      .isSignedIn()
      .subscribe((isSignedIn) => setSignedIn(isSignedIn));
    return () => subscription.unsubscribe();
  }, [auth, setSignedIn]);

  useEffect(() => {
    setProjects(data?.projects || []);
  }, [data, setProjects]);

  // Notes: This function needs to show loading either on the full page or on the row itself.
  // We only want one cancel at a time

  return (
    <Fade in={isSignedIn}>
      <Grid className={classes.root} container>
        <Grid item sm={12} md={9} lg={8}>
          <React.Fragment>
            <SiteAlert />
            <ProjectsHeader projects={projects} loading={loading && !error} />
            <Dialogs
              handleClose={() => {
                history.push("/");
                refetch();
              }}
            />

            <WaitingForQuotesList projects={projects} />
            <InProgressList projects={projects} />
            <CompletedList projects={projects} />
            <CanceledList projects={projects} />

            <WelcomePlaceholder projects={projects} />

            <UserBadge />
            {isSignedIn || <Redirect to={addNextParam("/sign-in")} />}
          </React.Fragment>
        </Grid>
      </Grid>
    </Fade>
  );
};

const ProjectsHeader = ({
  projects,
  loading,
}: {
  projects: ProjectsQuery_projects[];
  loading: boolean;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const analytics = useAnalytics();

  return (
    <div className={classes.header}>
      <Typography variant="h1">Your Projects</Typography>
      <Button
        id="new-project-button"
        className={classes.newProjectButton}
        color="primary"
        size="medium"
        pulsate={projects.length === 0 && !loading}
        primaryAction
        startIcon={<PlusIcon />}
        onClick={() => {
          analytics.event("Home Page", "Click Add Project");
          history.push("/new-project");
        }}
      >
        Add Project
      </Button>
    </div>
  );
};

const SiteAlert = () => {
  const classes = useStyles();
  const [alert, setAlert] = useState<string | null>(null);

  const { data, loading } = useQuery<SiteStatusQuery>(SITE_STATUS_QUERY);

  useEffect(() => {
    if (loading) {
      return;
    } else {
      setAlert(data?.siteStatus?.alert || null);
    }
  }, [data, loading]);

  return (
    <Fade in={alert != null}>
      <div>
        {alert && (
          <Alert
            className={classes.siteAlert}
            severity="warning"
            icon={<ExclamationTriangleIcon />}
          >
            {alert}
          </Alert>
        )}
      </div>
    </Fade>
  );
};

const Dialogs = ({ handleClose }: { handleClose: () => void }) => {
  const newProjectRoute = useRouteMatch("/new-project");
  const projectRoute = useRouteMatch<ProjectRouteParams>("/project/:projectId");
  const settingsRoute = useRouteMatch("/settings");
  const adminRoute = useRouteMatch("/admin");

  return (
    <React.Fragment>
      <NewProjectDialog
        open={newProjectRoute != null}
        onClose={() => handleClose()}
      />
      <ProjectDialog
        open={projectRoute != null}
        projectId={projectRoute?.params.projectId || ""}
        onClose={() => handleClose()}
      />
      <SettingsDialog
        open={settingsRoute != null}
        onClose={() => handleClose()}
      />
      <AdminDialog open={adminRoute != null} onClose={() => handleClose()} />
    </React.Fragment>
  );
};

const WaitingForQuotesList = ({
  projects,
}: {
  projects: ProjectsQuery_projects[];
}) => {
  return (
    <ProjectsList
      label="Waiting for a Quote"
      projects={projects.filter(
        (project) => project.status === ProjectStatus.WAITING_FOR_QUOTE
      )}
    />
  );
};

const InProgressList = ({
  projects,
}: {
  projects: ProjectsQuery_projects[];
}) => {
  return (
    <ProjectsList
      label="In Progress"
      projects={projects.filter(
        (project) =>
          project.status === ProjectStatus.IN_PROGRESS ||
          project.status === ProjectStatus.NOT_STARTED
      )}
    />
  );
};

const CompletedList = ({
  projects,
}: {
  projects: ProjectsQuery_projects[];
}) => {
  return (
    <ProjectsList
      label="Completed"
      projects={projects.filter(
        (project) => project.status === ProjectStatus.COMPLETED
      )}
    />
  );
};

const CanceledList = ({ projects }: { projects: ProjectsQuery_projects[] }) => {
  return (
    <ProjectsList
      label="Canceled"
      projects={projects.filter(
        (project) => project.status === ProjectStatus.CANCELED
      )}
    />
  );
};

const ProjectsList = ({
  label,
  projects,
}: {
  label: string;
  projects: ProjectsQuery_projects[];
}) => {
  const classes = useStyles();

  if (projects.length === 0) return null;

  return (
    <Fade in={true}>
      <div className={classes.projectsList}>
        <Typography variant="h4">
          {label} ({projects.length})
        </Typography>
        {projects.map((project) => (
          <ProjectRow key={project.id} project={project} />
        ))}
      </div>
    </Fade>
  );
};

interface ProjectRouteParams {
  projectId: string;
}

const ProjectRow = ({ project }: { project: ProjectsQuery_projects }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper
        className={clsx(classes.project)}
        onClick={() => history.push(`/project/${project.id}`)}
      >
        <Typography variant="body1">{project.name}</Typography>
        <ProjectSummary project={project} />
      </Paper>
    </React.Fragment>
  );
};

const ProjectSummary = ({ project }: { project: ProjectsQuery_projects }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.projectSummary)}>
      <LanguageSummary languages={[project.sourceLanguage]} justify="end" />
      <LongArrowRightIcon className={clsx(classes.projectSummaryArrow)} />
      <LanguageSummary languages={project.targetLanguages} justify="start" />
    </div>
  );
};

const LanguageSummary = ({
  languages,
  justify,
}: {
  languages: ProjectsQuery_projects_sourceLanguage[];
  justify: "start" | "end";
}) => {
  const classes = useStyles();
  const labels = languages.map(
    (language) => language.name || language.code.toUpperCase()
  );
  const value = labels.length > 1 ? `${labels.length} languages` : labels[0];

  return (
    <div
      className={clsx(classes.languageSummaryWrapper)}
      style={{ justifyContent: `flex-${justify}` }}
    >
      <div className={classes.languageSummary}>
        <Tooltip
          title={labels.length > 1 && labels.join("\n")}
          disabled={labels.length === 1}
        >
          <Typography variant="subtitle2" align="center" noWrap>
            {value}
          </Typography>
        </Tooltip>
      </div>
    </div>
  );
};

const WelcomePlaceholder = ({
  projects,
}: {
  projects: ProjectsQuery_projects[];
}) => {
  const classes = useStyles();
  if (projects.length > 0) {
    return null;
  }
  return (
    <Grid container>
      <Grid className={classes.graphic} item xs={12} lg={11}>
        <img
          className={classes.image}
          alt="Welcoming bugler"
          src={WelcomeGraphic}
        />
      </Grid>
    </Grid>
  );
};
